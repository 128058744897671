import React from 'react';

const TableComp = (props: { appState: any; }) => {
  const { appState } = props;

  return(
    <div className="pt-5 pb-5"><h5>DEBUG Window</h5>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>ID</th><th>model</th><th>プロセッサー</th><th>メインメモリー</th><th>ストレージ</th><th>表示方式</th>
          </tr>
        </thead><tbody>
      {appState.results && appState.results.length > 0 ? appState.results.map((result: { _source: { model_id: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; model_name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; attributes: any[]; }; },i: string) => {
        return (
          <tr key={"row_"+i}>
          <td><small>{result._source.model_id}</small></td><td><small>{result._source.model_name}</small></td>
          {result._source.attributes && result._source.attributes.length > 0 ? result._source.attributes.map((at: { attributes: { attribute_name: string; attribute_content: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }; },y: React.Key | null | undefined) => {
              return(
                <React.Fragment key={y}>
                {
                  (() => {
                    if(at.attributes.attribute_name === '表示方式'
                    || at.attributes.attribute_name === 'プロセッサー'
                    || at.attributes.attribute_name === 'プロッセッサー'
                    || at.attributes.attribute_name === 'メインメモリー'
                    || at.attributes.attribute_name === 'ストレージ') {
                      return (<td key={"row_"+i+"_"+y}><small key={"row_"+i+"_"+y}>{at.attributes.attribute_content}</small></td>)
                    }
                  })()
                }
                </React.Fragment>
              )
            }) : ""}
            </tr>
        );
      }) : <tr><td colSpan={6}></td></tr>}</tbody>
      </table></div>
  );
};
export default TableComp;
