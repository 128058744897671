import React from 'react';
import Category from './views/pages/Category';
import Toplib from './views/pages/Toplib';
import WebCatalog from './views/pages/WebCatalog';
import Library from './views/pages/Library';
import Lineup from './views/pages/Lineup';
import { BrowserRouter, Route, Switch} from 'react-router-dom';
import './styles/App.scss';
import { useAuth0 } from '@auth0/auth0-react';

function App() {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const RedirectToLogin: React.FC = () => {
    loginWithRedirect();
    return <></>;
  };

  const RedirectToLogout = () => {
    window.location.href = "https://panatech.c1x.biz/logout";
    return <></>;
  };

  const sessionStorageCache = {
    get: function (key:string) {
      return JSON.parse(sessionStorage.getItem(key)||"");
    },
  
    set: function (key:string, value:any) {
      sessionStorage.setItem(key, JSON.stringify(value));
    },
  
    remove: function (key:string) {
      sessionStorage.removeItem(key);
    },
  
    // Optional
    allKeys: function () {
      return Object.keys(sessionStorage);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

    if (isAuthenticated) {
      return (<>
      <header><nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
          <a href="https://panatech.c1x.biz/">
            <img src="https://archives.connect.panasonic.com/sav/common/global-libs/images/lgo-nav-global-panasonic.png" alt="Panasonic Business" width="80%" />
          </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarsExampleDefault">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item active">
            <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">Link</a>
          </li>
          <li className="nav-item">
            <a className="nav-link disabled" href="#">Disabled</a>
          </li>
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="http://example.com" id="dropdown01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Dropdown</a>
            <div className="dropdown-menu" aria-labelledby="dropdown01">
              <a className="dropdown-item" href="#">Action</a>
              <a className="dropdown-item" href="#">Another action</a>
              <a className="dropdown-item" href="#">Something else here</a>
            </div>
          </li>
        </ul>
        <form className="form-inline my-2 my-lg-0">
          <label className="col-sm-12" style={{color:'#fff'}}>{user && user.name}{' '}&nbsp;
          <button className="btn btn-outline-light my-2 my-sm-0" 
                    onClick={() => {
                      logout({ returnTo: window.location.origin });
                    }}
          >
            Log out
          </button></label>
      </form>
      </div>
      </nav></header>
      <BrowserRouter basename='/'>
        <Switch>
            <Route key="category" exact path="/" component={Category} />
            <Route key="toplib" exact path="/toplib" component={Toplib} />
            <Route key="library" exact path="/library/:id" component={Library} />
            <Route key="lineup" path="/lineup/:id" component={Lineup} />
            <Route key="webcatalog" path="/webcatalog" component={WebCatalog} />
        </Switch>
      </BrowserRouter>
      <footer className="container"><p>2017-2018 Company, Inc. <a href="#">Privacy</a></p></footer>
      </>);
    } else {
      return(<RedirectToLogin />);
    }
}

export default App;