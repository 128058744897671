import React,{useEffect,useState,useContext}  from 'react';
import { UserStore } from '../pages/Lineup';
import { getFileName } from '../../utils/Common';
import { getApi } from '../../utils/ApiUtils';
import { Link } from 'react-router-dom'
import { FaCube,FaQuestionCircle } from "react-icons/fa";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';

const CheckBox = ({id, value, checked, onChange, className}:{id:any, value:any, checked:any, onChange:any, className:any}) => {
  return (
    <input
      id={id}
      type="checkbox"
      name="inputNames"
      checked={checked}
      onChange={onChange}
      value={value}
      className={className}
    />
  )
}

const LeftNavi = () => {
  const [showItems, setAppState, initState, keyword, setKeyword, setShowItems,appState,toggled,handleToggleSidebar,prodTypeList,customSpecs, setCustomSpecs] = useContext<any>(UserStore);
  const [baseData, setBaseData] = useState<any>({
    textform1:null,
    checkbox1:null,
    select1:null
  });
  const [tmpState] = useState();
  const getData = () => {
    //const apiUrl = 'http://localhost:4040/api/search_all';
    const apiUrl = 'https://panatech.c1x.biz/api/custom';
    const id = getFileName();
    const body = {id:id};
    getApi(apiUrl,body,function(res: { data: { results: { _source: any; }[]; }; }){
      if(res.data) {
        updateCustomSpecs(res.data.results[0]._source);
      }
    });
  }
  const [checkedItems, setCheckedItems] = useState<any>({});
  const updateBaseData = (name: string,updateList: any) => {
    const baseHash:any = {};
    for(let item of updateList) {
      baseHash[item._source.model_id] = item._source.model_name;
    }
    baseData[name] = baseHash;
    setBaseData(() => {
      return baseData;
    });

    //console.log(JSON.stringify(baseData));
  }

  const filterBaseData = (updateList: any[]) => {
    updateList = updateList.filter((d: { _source: { model_id: string | number; }; }) => {
      if((baseData.textform1 == null || baseData.textform1[d._source.model_id]) && (baseData.checkbox1 == null || baseData.checkbox1[d._source.model_id]) && (baseData.select1 == null || baseData.select1[d._source.model_id])) {
        return true;
      }
      return false;
    })
    return updateList;
  }

  const updateCustomSpecs = (newCustomSpecs: any) => {
    setCustomSpecs(() => {
      return newCustomSpecs;
    });
  }

  const updateShowItems = (newShowItems: any) => {
    setShowItems(() => {
      return newShowItems;
    });
  }

  const updateCheckedItems = (newCheckedItems: any) => {
    setCheckedItems(() => {
      return newCheckedItems;
    });
  }

  const getCheckedItemsList = () => {
    const hash:any = {};
    for (let k of Object.keys(checkedItems)) {
      if(checkedItems[k].flag) {
        for(let v of checkedItems[k].list) {
          hash[v] = true;
        }
      }
    }
    return hash;
  }

  const handleClear = ((e: any) => {
    setKeyword('');
    if(!customSpecs) setAppState({ loading: false, results: initState.results });
  })
  
  /*
  const filterList = ((e) => {
    const checkedlist = getCheckedItemsList();
    if((!e.target.value && e.target.value.length <= 0) && (!customSpecs || !checkedlist || Object.keys(checkedlist).length === 0)) {
      setKeyword("");
      updateBaseData("textform1",initState.results);
      setAppState({ loading: false, results: filterBaseData(initState.results) });
    } else {
      if(!e.target.value && e.target.value.length <= 0) {
        setKeyword("");
        let updateList = initState.results.filter((item) => {
          if(!checkedlist || Object.keys(checkedlist).length === 0) {
            return true;
          } else {
            if(checkedlist[item._source.model_id]) {
              return true;
            }
          }
          return false;
        })
        updateBaseData("textform1",updateList);
        setAppState({ loading: false, results: filterBaseData(updateList) });
      } else {
        setKeyword(e.target.value);
        let updateList = initState.results.filter((item) => {
          if(!checkedlist || Object.keys(checkedlist).length === 0) {
            if(showItems[item._source.model_id]) {
              if(item._source.model_name.toLowerCase().search(e.target.value.toLowerCase()) !== -1) {
                showItems[item._source.model_id] = true;
                return true;
              }
              for(let attr of item._source.attributes) {
                if(attr.attributes.attribute_content.toLowerCase().search(e.target.value.toLowerCase()) !== -1) {
                  showItems[item._source.model_id] = true;
                  return true;
                }
              }
            }
            showItems[item._source.model_id] = false;
            return false;
          } else {
              if(checkedlist[item._source.model_id] && showItems[item._source.model_id]) {
                for(let attr of item._source.attributes) {
                  if(attr.attributes.attribute_content.toLowerCase().search(e.target.value.toLowerCase()) !== -1) {
                    showItems[item._source.model_id] = true;
                    return true;
                  }
                }
                showItems[item._source.model_id] = false;
                return false;
              }
          }
          showItems[item._source.model_id] = false;
          return false;
        })
        updateShowItems(showItems);
        updateBaseData("textform1",updateList);
        setAppState({ loading: false, results: filterBaseData(updateList) });
      }
    }
  })
  */

  /*
  const handleCheckBoxChange = (e) => {
    checkedItems[e.target.id].flag = e.target.checked;
    const checkedlist = getCheckedItemsList();
    if((!keyword || keyword.length === 0) && (!checkedlist || Object.keys(checkedlist).length === 0)) {
      updateBaseData("checkbox1",initState.results);
      setAppState({ loading: false, results: initState.results });
    } else {
      let updateList = initState.results.filter((item) => {
        if(!checkedlist || Object.keys(checkedlist).length === 0) {
          if(showItems[item._source.model_id]) {
            return true;
          }
        } else {
            if(checkedlist[item._source.model_id] && showItems[item._source.model_id]) {
              return true;
            }
        }
        return false;
      })
      updateBaseData("checkbox1",updateList);
      setAppState({ loading: false, results: filterBaseData(updateList) });
    }
  };
  */
  const filterList = ((e: { target: { value: string; }; }) => {
    if(!e.target.value && e.target.value.length <= 0) {
      setKeyword("");
      updateBaseData("textform1",initState.results);
      setAppState({ loading: false, results: filterBaseData(initState.results) });
    } else {
      setKeyword(e.target.value);
      let updateList = initState.results.filter((item: { _source: { model_id: string | number; attributes: any; }; }) => {
        if(showItems[item._source.model_id]) {
          for(let attr of item._source.attributes) {
            if(attr.attributes.attribute_content.toLowerCase().search(e.target.value.toLowerCase()) !== -1) {
              showItems[item._source.model_id] = true;
              return true;
            }
          }
          showItems[item._source.model_id] = false;
          return false;
        }
        showItems[item._source.model_id] = false;
        return false;
      })
      updateShowItems(showItems);
      updateBaseData("textform1",updateList);
      setAppState({ loading: false, results: filterBaseData(updateList) });
    }
  })

  const handleCheckBoxChange = (e: { target: { id: string | number; checked: any; }; }) => {
    checkedItems[e.target.id].flag = e.target.checked;
    const checkedlist = getCheckedItemsList();
    if(!checkedlist || Object.keys(checkedlist).length === 0) {
      updateBaseData("checkbox1",initState.results);
      setAppState({ loading: false, results: filterBaseData(initState.results) });
    } else {
      let updateList = initState.results.filter((item: { _source: { model_id: string | number; }; }) => {
        if(checkedlist[item._source.model_id]) {
          return true;
        }
        return false;
      })
      updateBaseData("checkbox1",updateList);
      setAppState({ loading: false, results: filterBaseData(updateList) });
    }
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if(e.target.value == "0") {
      updateBaseData("select1",initState.results);
      setAppState({ loading: false, results: filterBaseData(initState.results) });
    } else {
      let updateList = initState.results.filter((d: { _source: { product_type_list: any; }; }) => {
        for(let c of d._source.product_type_list) {
          if(e.target.value == c.id) {
            return true;
          }
        }
        return false;
      })
      updateBaseData("select1",updateList);
      setAppState({ loading: false, results:filterBaseData(updateList) });
    }
  };

  useEffect(() => {
    getData();
  }, [tmpState]);
  

  return (
    <ProSidebar
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            padding: '15px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: '1px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <Link style={{
              padding: '10px 10px',
              color: '#adadad',
            }} to='/'>トップへ戻る</Link>
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem
            style={{fontWeight:'bold'}}
            suffix={<span style={{backgroundColor:'#00730d',padding:'3px',color:'#fff'}}>{appState.results && appState.results.length > 0 ? appState.results.length : "0"} 件</span>}
          >
            検索結果：
          </MenuItem>
          <MenuItem>
              <input type="text" id="search" className="form-control" placeholder="キーワードで絞り込み" value={keyword} onChange={filterList}/>
              <div className="batsu2 close2" onClick={(e) => handleClear(e)}>×</div>
          </MenuItem>
          <MenuItem>
          <select className="custom-select custom-select-lg" onChange={(e) => handleSelectChange(e)}>
              <option key="0" className="custom-option" value="0">商品タイプを選択</option>
              {prodTypeList && Object.keys(prodTypeList).length > 0 ? Object.keys(prodTypeList).map((key,i) => {
                return(
                <React.Fragment key={"m_"+i}>
                <option key={i} value={key}>{prodTypeList[key]}</option>
                </React.Fragment>
                )
              }) : "OKOK"}
            </select>
          </MenuItem>
        </Menu>
        {(() => {if(customSpecs && customSpecs.custom_list.length > 0) return(
          <Menu iconShape="circle">
          {customSpecs && customSpecs.custom_list.length > 0 ? customSpecs.custom_list.map((line: { attribute_name: (string & (boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null)) | undefined; attribute_list: any[]; }, i: React.Key) => {
          return(
          <React.Fragment key={i}>
          <SubMenu
              key={i}
              title={line.attribute_name}
              icon={<FaCube />}
              defaultOpen={true}            
            >
              {line.attribute_list.map((item: (boolean | React.ReactChild | React.ReactFragment | React.ReactPortal)[], index: number) => {
                index = index + 1
          
                if(!checkedItems["id_"+i+"_"+index]) {
                  checkedItems["id_"+i+"_"+index] = {flag:false,list:item[1]};
                  updateCheckedItems(checkedItems);
                }
          
                return (
                  <MenuItem key={`menu_${i}_${index}`}>
                  <label htmlFor={`id_${i}_${index}`}>
                      <CheckBox
                      key={`id_${i}_${index}`}
                      id={`id_${i}_${index}`}
                      value={item[0]}
                      className="checkbox-input"
                      onChange={(e: any) => handleCheckBoxChange(e)}
                      checked={null}
                    />
                    <span className="checkbox-parts">{item[0]}</span>
                  </label>
                  </MenuItem>
                    )
                  })}
            </SubMenu>
            </React.Fragment>
        )
      }) : ""}
          </Menu>
        )})()}
      </SidebarContent>

      <SidebarFooter>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: '20px 24px',
          }}
        >
          <a
            href="https://askpc.panasonic.co.jp/s/adv/serviceinfo.html#contact"
            target="_blank"
            className="sidebar-btn no-external"
            rel="noopener noreferrer"
          >
            <FaQuestionCircle />
            <span>お問い合わせ</span>
          </a>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default LeftNavi;
