import React from 'react';

const ProductType = (props: { product_type_list: any; }) => {
  const { product_type_list } = props;
  //console.log("DEBUG:"+JSON.stringify(props));
  if (!product_type_list || product_type_list.length === 0) return null;
  return (
    <ul>
      {product_type_list.map((product_type: { id: any; name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; },i: React.Key | null | undefined) => {
        return (
          <small key={i}><span className="badge badge-secondary" data-product_type-id={product_type.id}>{product_type.name}</span>&nbsp;</small>
        );
      })}
    </ul>
  );
};
export default ProductType;
