import React, { useEffect, useState } from 'react';
import { Link,useLocation } from 'react-router-dom';
import {getApi,getUrlApi} from '../../utils/ApiUtils';

const WebCatalog = () =>  {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const displayCount = 6;
  const [state, setState] = useState<any>();
  const [baseList, setBaseList] = useState<any>();
  const [mediaList, setMediaList] = useState<any>();
  const [mediaCount, setMediaCount] = useState<any>();
  const [isDataGet,setIsDataGet] = useState(true);
  const getData = (id:any,doc:any) => {
    let apiUrl = "";
    if(doc && doc.length > 0) {
      apiUrl = 'https://panatech.c1x.biz/spec/getmedia?id='+id+'&doc='+doc;
    } else {
      apiUrl = 'https://panatech.c1x.biz/spec/getmedia?id='+id;
    }
    
    getUrlApi(apiUrl,function(res: { data: any; }){
      setBaseList(res.data);
      setMediaCount(res.data.length);
      const ary = res.data.filter((val:any, i:any) => {
        if(i >= 0 && i < displayCount) {
          return true;
        }
      })
      setMediaList(ary);
      
    });
  }

  const setPageData = (pageNum:any) => {
    let start = pageNum <= 0 ? 0 : pageNum*displayCount;
    let end = pageNum <= 0 ? displayCount : (pageNum*displayCount)+displayCount;
    const ary = baseList.filter((val:any, i:any) => {
      if(i >= start && i < end) {
        return true;
      }
    })
    setMediaList(ary);
  };

  const setStateInfoAction = (value:any, type:any) => {
    switch(type) {
      case 'selectPage':
        setPageData(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if(isDataGet) {
      getData(query.get('id'),query.get('doc'));
    }
    setIsDataGet(false);
    
  }, [isDataGet]);

  return (
    <>
    <div className="app">
    <main>
      <div key="main" className="container-fluid">
      <div className="row row-eq-height pt-3">
      {mediaList ? mediaList.map((val:any, i:any) => {
                return(
                  <div key={"row_"+i} className="card-base col-md-2 col-sm-6 p-1">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title small">{mediaList[i].name}</h5>
                      <h6 className="card-subtitle mt-1 text-muted small" >{mediaList[i].document_types.attributes.name}/{mediaList[i].document_types.attributes.pname}({mediaList[i].document_types.id})</h6>
                      <img src={mediaList[i].thumbnail} alt="product_icon" />
                    </div>
                  <div className="card-body">
                  <p className="small">{mediaList[i].search_text}</p>
                  <p className="small">ファイル名：{mediaList[i].psd_media_filename}</p>
                  </div>
                  <div className="card-footer">
                  <p className="card-text"><a href={mediaList[i].href} className="btn btn-primary text-white" rel="noopener noreferrer" download="dl.txt" target="_blank">ダウンロード</a></p>
                  </div>
                  </div>
                  </div>
                );
      }) : <p>Loading...</p>}
          </div>
    </div>
      </main>
      </div>
  </>);
}

export default WebCatalog;
