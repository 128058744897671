import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getApi } from '../../utils/ApiUtils';

const Category = () =>  {
  const [cateList, setCateList] = useState<any>();
  const [isDataGet,setIsDataGet] = useState(true);
  const getData = () => {
    //const apiUrl = 'http://localhost:4040/api/catelist';
    const apiUrl = 'https://panatech.c1x.biz/api/catelist';
    const body = {id:''};
    getApi(apiUrl,body,function(res: { data: { results: any[]; }; }){
      setCateList(res.data.results[0]);
    });
  }

  useEffect(() => {
    if(isDataGet) {
      getData();
    }
    setIsDataGet(false);
    
  }, [isDataGet]);

  return (
    <div className="app">
      <div className="container-fluid">
      <h3 className="header center pt-3 pb-3">Spec Search</h3>

          <div className="row row-eq-height">
      {cateList ? Object.keys(cateList._source).map((key, i) => {
        return (
            <div key={key} className="card-base col-lg-3 col-sm-6 p-1">
            <div className="card">
            <div className="card-body">
            <p>{cateList._source[key].category_name+'('+cateList._source[key].count+')'}</p>
            </div>
            <div className="card-footer">
            <p className="card-text"><Link className="btn btn-primary text-white" to={'/lineup/' + key}>カテゴリ検索</Link></p>
            </div>
            </div>
            </div>
        );
      }) : <p>Loading...</p>}
                </div>
    </div>
    </div>
  );
}

export default Category;
