import React from 'react';

export const getFileName = () => {
    return window.location.href.split('/').pop() || "";
}

export const getCheckedItemsList = (h: { [x: string]: { list:any,flag: any; }; }) => {
    const hash:any = {};
    for (let k of Object.keys(h)) {
      if(h[k].flag) {
        for(let v of h[k].list) {
          hash[v] = true;
        }
      }
    }
    return hash;
}

export const doHighlight = (mess: string,query: string,key: React.Key | null | undefined) => {
  const result = [];
  const limit = 5;
  let i = -1;
  let cnt = 0;
  let a3 = "";
  
  if(query) query = query.trim();
  while(cnt <= limit) {
    if(typeof mess === 'string' && (i = mess.indexOf(query)) !== -1) {
      let a1 = mess.slice(0,i);
      let a2 = mess.slice(i, i+query.length);
      a3 = mess.slice(i + query.length);
      if (a1) result.push(a1);
      if (a2) result.push(<mark key={key+"-"+cnt}>{a2}</mark>);
      mess = a3;
    } else {
        if ((result && result.length > 0) && a3) result.push(a3);
      break;
    }
    cnt++;
  }

  if(result && result.length > 0) return result;
  return mess;
}
