import axios, { AxiosResponse } from 'axios';

export const getApi = (apiUrl: string,body: { id: string | undefined; },callback: { (res: any): void; (res: any): void; (res: any): void; (res: { data: { results: { _source: any; }[]; }; }): void; (arg0: AxiosResponse<any>): void; }) => {
    const axiosConfig = {
        headers: {
            "Authorization": "Basic cGFuYXNvbmljOnByb2N0b3Jz"
        }
    };
    axios.post(apiUrl,body,axiosConfig).then((res) => {
        callback(res);
    });
}

export const getUrlApi = (apiUrl: string,callback: { (res: any): void; (res: any): void; (res: any): void; (res: { data: { results: { _source: any; }[]; }; }): void; (arg0: AxiosResponse<any>): void; }) => {
    axios.get(apiUrl).then((res) => {
        callback(res);
    });
}
