import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from "@auth0/auth0-react";
import { createBrowserHistory } from "history";

const onRedirectCallback = (appState:any) => {
  createBrowserHistory().push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const providerConfig = {
  "domain": "dev-c1x.jp.auth0.com",
  "clientId": "HwRZhK2GPWFVvFbgDQKWEAxZO87Yd10J",
  "audience": "https://dev-c1x.jp.auth0.com/api/v2/",
  redirectUri: window.location.origin
};

ReactDOM.render(
  <Auth0Provider {...providerConfig}>
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();