import React, { useEffect, useState } from 'react';
import Attribute from '../components/Attribute';
import TableComp from '../components/TableComp';
import ProductType from '../components/ProductType';
import { getFileName,doHighlight } from '../../utils/Common';
import { getApi } from '../../utils/ApiUtils';
import { FaBars } from 'react-icons/fa';
import LeftNavi from '../components/LeftNavi';

export const UserStore = React.createContext({});

const Lineup = () =>  {

  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = (value: boolean | ((prevState: boolean) => boolean)) => {
    setToggled(value);
  };

  const [initState, setInitState] = useState({
    loading: true,
    results: null,
  });
  const [appState, setAppState] = useState<any>({
    loading: true,
    results: null,
  });
  const [isDataGet,setIsDataGet] = useState(true);
  const [keyword, setKeyword] = useState("");

  const [showItems, setShowItems] = useState<any>({});
  const [prodTypeList, setProdTypeList] = useState();

  const [customSpecs, setCustomSpecs] = useState<any>();

  const handleCloseClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>,model_id: string | number) => {
    showItems[model_id] = false;
    let updateList = appState.results.filter((val: { _source: { model_id: any; }; }) => val._source.model_id !== model_id);
    setAppState({ loading: false, results: updateList });
  };
  
  const getData = () => {
    //const apiUrl = 'http://localhost:4040/api/search_all';
    const apiUrl = 'https://panatech.c1x.biz/api/search_all';
    const body = {id:getFileName()};
    getApi(apiUrl,body,function(res: { data: { results: any; }; }){
      //var results = res.data.results.filter(val => val._source.category_id == getFileName());
      var results = res.data.results;
      setInitState({ loading: false, results: results });
      setAppState({ loading: false, results: results });
      getProdTypeList(results);
    });
  }

  const handleClear = ((e: any) => {
    setKeyword('');
    if(!customSpecs) setAppState({ loading: false, results: initState.results });
  })

  const getProdTypeList = (d: any) => {
    let h:any = {};
    if(d) {
      for(let v of d) {
        for(let c of v._source.product_type_list) {
          h[c.id] = c.name;
        }
      }
      setProdTypeList(h);
    }
  }

  useEffect(() => {
    if(isDataGet) {
      getData();
    }
    setIsDataGet(false);
    
  }, [isDataGet]);

  return (
    <div className={`app ${toggled ? 'toggled' : ''}`}>
      <UserStore.Provider value={[showItems, setAppState, initState, keyword, setKeyword, setShowItems,appState,toggled,handleToggleSidebar,prodTypeList,customSpecs, setCustomSpecs]}>
        <LeftNavi />
      </UserStore.Provider>
    <main>
    <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
      <FaBars />
    </div>
      <div key="main" className="container-fluid">
      <h3 className="header center">{appState.results && appState.results.length > 0 ? appState.results[0]._source.category_name : ""}</h3>
        {
          (() => {
            if(keyword && keyword.length > 0) {
              return <div className="badge-word">{keyword}<span className="batsu3 close3" onClick={(e) => handleClear(e)}>×</span></div>
            }
          })()
        }
        <div className="row row-eq-height pt-3">
      {appState.results && appState.results.length > 0 ? appState.results.map((result: { _source: { model_id: string | number; series_id: any; series_name: string; model_name: string; main_image: string | undefined; product_type_list: any; attributes: any; series_url: string; }; },i: React.Key | null | undefined) => {
        if(!showItems[result._source.model_id]) {
          showItems[result._source.model_id] = true;
        }
        
        return (
            <div key={"row_"+i} className="card-base col-md-3 col-sm-6 p-1">
            <div className="card">
              <div className="card-header">
                <span className="close"><div className="batsu" onClick={(e) => handleCloseClick(e,result._source.model_id)}>×</div></span><h5 className="card-title" data-series-id={result._source.series_id}>{result._source.series_name.replace(/<br>|<SUP>|※|＊|<\/SUP>/gi,"")}</h5>
                <h6 className="card-subtitle mt-1 text-muted" data-model-id={result._source.model_id}>{doHighlight(result._source.model_name,keyword,i)}</h6>
                <img src={result._source.main_image} alt="product_icon" />
                <ProductType product_type_list={result._source.product_type_list} />
              </div>
            <div className="card-body">
            <Attribute index={i} attributes={result._source.attributes} keyword={keyword} />
            </div>
            <div className="card-footer">
            <p className="card-text"><a href={'https://biz.panasonic.com/jp-ja'+result._source.series_url} className="btn btn-primary text-white" rel="noopener noreferrer" target="_blank">シリーズページ</a></p>
            </div>
            </div>
            </div>
        );
      }) : ""}
          </div>
          <TableComp appState={appState} />
    </div>
      </main>
      </div>
  );
}

export default Lineup;
