import React, { useEffect, useState } from 'react';
import { getApi } from '../../utils/ApiUtils';
import { getFileName,doHighlight } from '../../utils/Common';
import { FaCube,FaQuestionCircle } from "react-icons/fa";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { Link } from 'react-router-dom';

export const UserStore = React.createContext({});

const Library = () =>  {
  const [mediaList, setMediaList] = useState<any>();
  const [baseList, setBaseList] = useState<any>();
  const [fileTypeList, setFileTypeList] = useState<any>();
  const [isDataGet,setIsDataGet] = useState(true);
  const getData = () => {
    //const apiUrl = 'http://localhost:4040/api/catelist';
    const apiUrl = 'https://panatech.c1x.biz/api/medialist';
    const body = {id:''};
    getApi(apiUrl,body,function(res: { data: any; }){
      const list = res.data[getFileName()];
      setMediaList(list);
      setBaseList(list);

      
      //const result = list.map((item:any) => item.document_types.attributes.name);
      const result = Array.from(
        new Map(list.map((item:any) => [item.document_types.id, item.document_types.attributes.name])).values()
      );
      //console.log(result);
      setFileTypeList(result);
    });
  }
  const [keyword, setKeyword] = useState("");
  const filterList = ((e: { target: { value: string; }; }) => {
    if(!e.target.value && e.target.value.length <= 0) {
      setKeyword("");
      setMediaList(baseList);
    } else {
      setKeyword(e.target.value);
      let updateList = mediaList.filter((item:any) => {
        if(item.search_text.toLowerCase().search(e.target.value.toLowerCase()) !== -1) {
          return true;
        }
      })
      setMediaList(updateList);
    }
  })
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if(e.target.value == "0") {
      setMediaList(baseList);
    } else {
      console.log(baseList);
      let updateList = baseList.filter((d: any) => {
        if(e.target.value == d.document_types.attributes.name) {
          console.log(e.target.value);
          return true;
        }
        return false;
      })
      setMediaList(updateList);
    }
  };
  useEffect(() => {
    if(isDataGet) {
      getData();
    }
    setIsDataGet(false);
    
  }, [isDataGet]);
  return (
    <div className="app">
    <ProSidebar>
      <SidebarHeader>
        <div
          style={{
            padding: '15px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: '1px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <Link style={{
              padding: '10px 10px',
              color: '#adadad',
            }} to='/toplib'>トップへ戻る</Link>
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle">
           <MenuItem
            style={{fontWeight:'bold'}}
            suffix={<span style={{backgroundColor:'#00730d',padding:'3px',color:'#fff'}}>{mediaList && mediaList.length > 0 ? mediaList.length : "0"} 件</span>}
          >
            検索結果：
          </MenuItem>
          <MenuItem>
              <input type="text" id="search" className="form-control" placeholder="キーワードで絞り込み" value={keyword} onChange={filterList} />
          </MenuItem>
          <MenuItem>
          <select className="custom-select custom-select-lg" onChange={(e) => handleSelectChange(e)}>
              <option key="0" className="custom-option" value="0">ファイルタイプを選択</option>
              {fileTypeList && fileTypeList.length > 0 ? fileTypeList.map((item: (boolean | React.ReactChild | React.ReactFragment | React.ReactPortal)[], index: number) => {
                return(
                <React.Fragment key={"m_"+index}>
                <option key={index} value={fileTypeList[index]}>{fileTypeList[index]}</option>
                </React.Fragment>
                )
              }) : "OKOK"}
            </select>
          </MenuItem>
        </Menu>
      </SidebarContent>

      <SidebarFooter>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: '20px 24px',
          }}
        >
          <a
            href="https://askpc.panasonic.co.jp/s/adv/serviceinfo.html#contact"
            target="_blank"
            className="sidebar-btn no-external"
            rel="noopener noreferrer"
          >
            <FaQuestionCircle />
            <span>お問い合わせ</span>
          </a>
        </div>
      </SidebarFooter>
    </ProSidebar>
    <main>
      <div key="main" className="container-fluid">
      <div className="row pt-3">
      {mediaList && mediaList.length > 0 ? mediaList.map((result:any,y:React.Key) => {
        return(
          <div key={"row_"+y} className="card-base col-md-2 col-sm-6 p-1">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title small">{result.name}</h5>
              <h6 className="card-subtitle mt-1 text-muted small" >{result.document_types.attributes.name}/{result.document_types.attributes.pname}({result.document_types.id})</h6>
              <img src={result.thumbnail} alt="product_icon" />
            </div>
          <div className="card-body">
          <p className="small">{result.search_text}</p>
          <p className="small">ファイル名：{result.psd_media_filename}</p>
          </div>
          <div className="card-footer">
          <p className="card-text"><a href={result.href} className="btn btn-primary text-white" rel="noopener noreferrer" download="dl.txt" target="_blank">ダウンロード</a></p>
          </div>
          </div>
          </div>
        );
      }) : <p>Loading...</p>}
          </div>
    </div>
      </main>
      </div>
  );
}

export default Library;
