import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getApi } from '../../utils/ApiUtils';

const Toplib = () =>  {
  const displayCount = 20;
  const [state, setState] = useState<any>();
  const [baseList, setBaseList] = useState<any>();
  const [mediaList, setMediaList] = useState<any>();
  const [mediaCount, setMediaCount] = useState<any>();
  const [isDataGet,setIsDataGet] = useState(true);
  const getData = () => {
    //const apiUrl = 'http://localhost:4040/api/catelist';
    const apiUrl = 'https://panatech.c1x.biz/api/medialist';
    const body = {id:''};
    getApi(apiUrl,body,function(res: { data: any; }){
      let hash:any = {};
      Object.keys(res.data).map((key, i) => {
        if(res.data[key] && res.data[key].length > 0) {
          hash[key] = res.data[key]
        }
      })
      setBaseList(hash);
      setMediaCount(Object.keys(hash).length);
      const hash2:any = {};
      Object.keys(hash).map((key, i) => {
        if(i >= 0 && i < displayCount) {
            hash2[key] = hash[key];
        }
      })
      setMediaList(hash2);
    });
  }

  const setPageData = (pageNum:any) => {
    const hash:any = {};
    let start = pageNum <= 0 ? 0 : pageNum*displayCount;
    let end = pageNum <= 0 ? displayCount : (pageNum*displayCount)+displayCount;
    Object.keys(baseList).map((key, i) => {
      if(i >= start && i < end) {
          hash[key] = baseList[key];
      }
    })
    setMediaList(hash);
  };

  const setStateInfoAction = (value:string, type:string) => {
    switch(type) {
      case 'selectPage':
        setPageData(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if(isDataGet) {
      getData();
    }
    setIsDataGet(false);
    
  }, [isDataGet]);

  return (
    <>
    <div className="app">
    <main>
      <div key="main" className="container-fluid">
      <div className="row row-eq-height pt-3">
      {mediaList ? Object.keys(mediaList).map((key, i) => {
        if(mediaList[key] && mediaList[key].length > 0) {
                return(
                  <div key={"row_"+i} className="card-base col-lg-3 col-sm-6 p-1">
                  <div className="card">
                  <div className="card-body">
                  <p>{key}：({mediaList[key].length})件</p>
                  </div>
                  <div className="card-footer">
                  <p className="card-text"><Link className="btn btn-primary text-white" to={'/library/' + key}>カテゴリ検索</Link></p>
                  </div>
                  </div>
                  </div>
                );
        }
      }) : <p>Loading...</p>}
          </div>
    </div>
      </main>
      </div>
  </>);
}

export default Toplib;
