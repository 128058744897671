import React from 'react';
import { doHighlight } from '../../utils/Common';

const Attribute = (props: { attributes: any; index: any; keyword: any; }) => {
  const { attributes, index, keyword } = props;

  if (!attributes || attributes.length === 0) return null;
  return (
    <p className="card-text more" tabIndex={index}>
      {attributes.map((attr: { attributes: { attribute_name: string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined; attribute_content: any; }; },i: React.Key | null | undefined) => {
        return (
            <span key={i} className="br"><b>{attr.attributes.attribute_name}: </b>
            {doHighlight(attr.attributes.attribute_content,keyword,i)}
            </span>
        );
      })}
    </p>
  );
};
export default Attribute;
